.connectingIcon {
  animation: connecting-icon-spin infinite 2s linear;
}

@keyframes connecting-icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
